import React, { useState } from 'react';
import {
    Link,
} from 'react-router-dom';

import logoColor from './svensk_portkod_black.svg';
import logoWhite from './svensk_portkod_white.svg';
import burgerWhite from './burger_white.svg';
import burgerBlack from './burger_black.svg';

export default (properties) => {
    const { color } = properties;

    const [open, setOpen] = useState(false);

    return <nav className="navbar navbar-expand-sm py-4 px-5">
        <Link to="/" className="navbar-brand">
            <img src={color ? logoColor : logoWhite} alt="Svensk Portkod" width="110" />
        </Link>
        <button className="navbar-toggler" style={{ margin: 0, padding: 0 }} type="button" onClick={() => setOpen(!open)}>
            <img src={color ? burgerBlack : burgerWhite} width="30" />
        </button>
        <div className={`collapse navbar-collapse ${open ? 'show' : ''}`} id="sp-navbar">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <Link to="/blogg" className="nav-link">
                        Blogg
                    </Link>
                </li>
                <li className="nav-item ml-3">
                    <Link to="/faq" className="nav-link">
                        Frågor och svar
                    </Link>
                </li>
                <li className="nav-item  ml-3 d-none d-sm-inline-block">
                    <Link to="/order" className="btn btn-primary btn-sm btn-order">
                        Byt kod nu
                    </Link>
                </li>
                <li className="nav-item d-inline-block d-sm-none">
                    <Link to="/order" className="nav-link">
                        Byt kod nu
                    </Link>
                </li>
            </ul>
        </div>
    </nav>;
};