import React from 'react';

import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import logo from './svensk_portkod_black.svg';


function NoMatch() {
    const location = useLocation();

    return <>
        <header className="sp-white pb-5">
            <nav className="navbar navbar-expand-md pt-4 px-5 sp-white">
                <Link to="/" className="navbar-brand">
                    <img src={logo} alt="Svensk Portkod" width="110px" />
                </Link>
                <div className="collapse navbar-collapse" id="sp-navbar">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item ml-4">
                            <Link to="/order" className="btn btn-primary btn-sm btn-order">
                                Meddela ändring
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <main className="sp-white pb-5">
            <section className="container">
                <div className="col-md-4 mb-5">
                    <h1>
                        404
                    </h1>
                </div>
                <div className="col-md-8">
                    Sidan
                    {' '}
                    <code>{location.pathname}</code>
                    {' '}
                    finns tyvärr inte.
                </div>
            </section>
        </main>
    </>;
}

export default NoMatch;