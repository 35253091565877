import React from 'react';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { HomescreenForm } from '../feedback';
import Nav from '../nav';
import './brochure.scss';

import downArrow from './down_arrow.svg';
import logoCitymail from '../logos/logo_citymail_white.svg';
import logoPostnord from '../logos/logo_postnord_white.svg';
import logoBonway from '../logos/logo_bonway_white.svg';
import logoPoint from '../logos/logo_point_white.svg';
import logoPremo from '../logos/logo_premo_white.svg';
import logoHemleverans from '../logos/logo_hemleverans_white.svg';
import logoNwtDirekt from '../logos/logo_nwt_direkt_white.svg';
import logoEarlyBird from '../logos/logo_early_bird_white.svg';
import logoVtd from '../logos/logo_vtd_white.svg';
import ssf from './ssf.svg';
import symbolWhiteReverse from './symbol_white_reverse.svg';
import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import pris from './pris.svg';

// eslint-disable-next-line import/no-unresolved
import header from './header.jpg?sizes[]=200,sizes[]=500,sizes[]=1000';
// eslint-disable-next-line import/no-unresolved
import sakerRapportering from './saker_rapportering.jpg?sizes[]=200,sizes[]=500,sizes[]=1000';
// eslint-disable-next-line import/no-unresolved
import behorigaBesokare from './behoriga_besokare.jpg?sizes[]=200,sizes[]=500,sizes[]=1000';
// eslint-disable-next-line import/no-unresolved
import signOff from './signoff.jpg?sizes[]=200,sizes[]=500,sizes[]=1000';



export default () => (
    <>
        <header className="sp-black d-flex flex-column position-relative min-vh-100">
            <img className="fill-image" alt="" srcSet={header.srcSet} src={header.src} />
            <div className="image-overlay" style={{ opacity: 0.3 }} />
            <Nav />
            <div className="container d-flex flex-column justify-content-center flex-grow-1">
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 text-center">
                        <h1>
                            När bytte ni portkod senast?
                        </h1>
                        <p className="slogan">
                            Svensk Portkod är en samhällstjänst som säkert förmedlar
                            portkoder till behöriga besökare som delar ut post, paket och morgontidningar
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center mt-4 ">
                    <div className="col-10 col-sm-8">
                        <p className="text-center text-uppercase">I samarbete med</p>
                        <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                            <img className="delivery-logo" src={logoCitymail} alt="Citymail" />
                            <img className="delivery-logo" src={logoPremo} alt="Premo" />
                            <img className="delivery-logo delivery-logo-wider" src={logoHemleverans} alt="Svensk Hemleverans" />
                            <img className="delivery-logo" src={logoPoint} alt="Point" />
                            <img className="delivery-logo delivery-logo-wider" src={logoEarlyBird} alt="Early Bird" />
                            <img className="delivery-logo delivery-logo-wider" src={logoBonway} alt="Bonway" />
                            <img className="delivery-logo delivery-logo-wider" src={logoNwtDirekt} alt="NWT Direkt" />
                            <img className="delivery-logo delivery-logo-wider" src={logoPostnord} alt="Postnord" />
                            <img className="delivery-logo" src={logoVtd} alt="VTD" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center my-4 ">
                    <div className="col d-flex flex-column">
                        <HashLink to="#las-mer" className="btn btn-secondary btn-sm mx-auto">
                            Läs mer
                        </HashLink>
                        <Link
                            className="btn btn-primary btn-sm btn-order d-sm-none mx-auto mt-4"
                            to="/order"
                            role="button"
                        >
                            Byt kod nu
                        </Link>
                    </div>
                </div>
            </div>
        </header>
        <section id="las-mer" className="sp-black container-fluid py-5 px-5 position-relative min-vh-50 d-flex flex-column justify-content-center">
            <img src={pris} height="120" alt="Pris 399 kr" className="position-absolute price-tag" />
            <div className="row justify-content-center">
                <div className="col text-center" style={{ position: 'relative' }}>
                    <h2>Så här fungerar det</h2>
                    <hr className="my-4" />
                </div>
            </div>
            <div className="row justify-content-center px-5 text-center">
                <div className="col-sm-4">
                    <img src={step1} height="45" alt="Steg 1" />
                    <h4 className="sp-fg-yellow w-100 my-3">1</h4>
                    <p>
                        Du meddelar en planerad ändring av portkod minst 24 timmar i förväg,
                        för en eller flera portar
                    </p>
                </div>
                <div className="col-sm-4 py-4">
                    <img src={step2} height="45" alt="Steg 2" />
                    <h4 className="sp-fg-yellow w-100 my-3">2</h4>
                    <p>
                        Vi förmedlar säkert koden till alla behöriga besökare
                    </p>
                </div>
                <div className="col-sm-4">
                    <img src={step3} height="55" alt="Steg 3" style={{ marginLeft: '20px' }} />
                    <h4 className="sp-fg-yellow w-100 my-3">3</h4>
                    <p>
                        Du ändrar antingen själv koden natten till meddelat datum, eller så använder
                        du en av våra
                        {' '}
                        <HashLink to="/faq#hjalp" className="underlined-link">
                            rekommenderade låssmeder.
                        </HashLink>
                    </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <Link
                    className="btn btn-secondary btn-order btn-order-black-arrow"
                    to="/order"
                    role="button"
                >
                    Byt kod nu
                </Link>
            </div>
        </section >
        <section id="fragor" className="sp-blue container-fluid p-5 min-vh-50">
            <div className="row justify-content-center">
                <div className="col-sm-6 col-md-4 text-center">
                    <h2>Frågor?</h2>
                    <hr className="my-4" />
                    <p className="mb-4">
                        Har du frågor om hur Svensk Portkod kan hjälpa dig hålla fastigheten säker?
                    </p>
                    <HomescreenForm />
                </div>
            </div>
        </section>
        <section className="sp-light-blue container-fluid">
            <div className="row">
                <div className="col-md-6 order-md-12 d-flex align-items-center text-center min-vh-50">
                    <img className="fill-image" alt="" srcSet={sakerRapportering.srcSet} src={sakerRapportering.src} />
                    <div className="image-overlay" style={{ opacity: 0.3 }} />
                    <img
                        className="d-block d-md-none"
                        src={symbolWhiteReverse}
                        width="50"
                        alt=""
                        style={{ position: 'absolute', top: '2rem', left: '2rem' }}
                    />
                    <h1 className="statement-text w-100 p-5">
                        Säker
                        <br />
                        rapportering
                    </h1>
                </div>
                <div className="col-md-6 order-md-1 d-flex flex-column justify-content-center text-center p-5 min-vh-50">
                    <h2 className="text-center mx-5">Detta gör vi</h2>
                    <hr className="my-4" />
                    <p>
                        Svensk Portkod hjälper dig som förvaltare att säkert meddela ändring av portkod (ibland
                        kallad
                        {' '}
                        <i>dörrkod</i>
                        ) till de behöriga besökarna till dina fastigheter, som exempelvis post- och
                        tidningsutdelare.
                    </p>
                    <p>
                        Portkoder för behöriga besökare (ofta kallad
                        {' '}
                        <i>postkod</i>
                        {' '}
                        eller
                        {' '}
                        <i>postportkod</i>
                        ) bör med jämna mellanrum bytas för att stänga obehöriga
                        ute.
                    </p>
                    <p>
                        Svensk Portkod är branschens centrala plats för att meddela ändringar av
                        portkoder.
                    </p>
                </div>
            </div>
        </section>
        <section className="sp-blue container-fluid">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center text-center min-vh-50">
                    <img className="fill-image" alt="" srcSet={behorigaBesokare.srcSet} src={behorigaBesokare.src} />
                    <div className="image-overlay" style={{ opacity: '0.1' }} />
                    <h1 className="statement-text w-100 p-5">
                        Behöriga
                        <br />
                        besökare
                    </h1>
                    <img
                        className="d-block d-md-none"
                        src={symbolWhiteReverse}
                        alt=""
                        width="50"
                        style={{ position: 'absolute', bottom: '2rem', right: '2rem' }}
                    />
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center p-5 min-vh-50">
                    <img src={downArrow} height="50" alt="" />
                    <p className="text-center mt-5 mb-4 mx-5" style={{ fontSize: '1rem' }}>
                        Vi rekommenderar att man minst byter kod en gång om året. Det är en
                        säkerhetsfråga att ha koll på att föreningens skalskydd funkar som det ska och att det
                        håller en hög säkerhet.
                    </p>
                    <hr className="my-4" />
                    <img src={ssf} height="35" alt="SSF" className="my-3" />
                </div>
            </div>
        </section>
        <section className="om-oss sp-light-blue container-fluid py-5 min-vh-50">
            <div className="row justify-content-center">
                <div className="col-sm-6 p-5 text-center">
                    <h2>Om oss</h2>
                    <hr className="my-4" />
                    <p>
                        Idag finns ett antal behöriga besökare till flerfamiljsbostäder som delar ut
                        post, paket och morgontidningar till boende. Detta är möjligt genom att en särskild portkod används
                        och Svensk Portkod är branschens tjänst för att meddela portkoder för behöriga besökare.
                    </p>
                    <p className="mt-5">
                        Alla medlemmar i Svensk Portkod har beprövade processer att hålla fastighetskoder säkra.
                        Tillsammans har vi byggt en säker tjänst för att förmedla portkoder.
                    </p>
                </div>
            </div>
        </section>
        <section className="container-fluid min-vh-50">
            <div className="row justify-content-center position-relative">
                <img className="fill-image" alt="" srcSet={signOff.srcSet} src={signOff.src} />
                <img
                    src={symbolWhiteReverse}
                    width="100"
                    height="auto"
                    alt="Svensk Portkod"
                    style={{ zIndex: '10', margin: '8rem' }}
                />
            </div>
        </section>
    </>
);
