import React from 'react';

import logo from './svensk_portkod_white.svg';
import facebookLogo from './facebook-square.svg';
import linkedinLogo from './linkedin-square.svg';

export default () => (
    <footer className="py-4 px-4 px-sm-5 sp-black d-flex justify-content-between" style={{ overflow: 'hidden' }}>
        <img src={logo} height="40" alt="Svensk Portkod" />
        <div className="d-flex flex-column pb-5 flex-sm-row justify-content-end">
            <div className="snabblankar pr-sm-5">
                <b>Snabblänkar</b>
                <hr />
                <ul>
                    <li><a href="/faq">Frågor och svar</a></li>
                    <li><a href="/order">Meddela ändring</a></li>
                    <li><a href="/blogg">Blogg</a></li>
                    <li><a href="/personuppgifter">Personuppgiftspolicy</a></li>
                </ul>
                <p>Orgnr. 559047-8367</p>
            </div>
            <div className="kontakt">
                <b>Kontakt</b>
                <hr />
                <p>
                    Svensk&nbsp;Portkod&nbsp;AB
                    <br />
                    Fatburs&nbsp;Kvarngata&nbsp;44
                    <br />
                    118&nbsp;64&nbsp;Stockholm
                </p>
                <p><a href="mailto:info@svenskportkod.se">info@svenskportkod.se</a></p>
                <a href="https://www.facebook.com/svenskportkod/">
                    <img alt="Facebook" className="mr-2" src={facebookLogo} width="20" />
                </a>
                <a href="https://www.linkedin.com/company/svensk-portkod-ab">
                    <img alt="LinkedIn" src={linkedinLogo} width="20" />
                </a>
            </div>
        </div>
    </footer>
);
