import React from 'react';
import Nav from './nav';
import './privacy.scss';


export default () => (
    <>
        <header className="sp-white pb-5">
            <Nav color={true} />

        </header>
        <main className="sp-white">
            <section className="container privacy">
                <div className="row p-5">
                    <div className="col-md-4 mb-5">
                        <h2>
                            Person
                            <wbr />
                            uppgifts
                            <wbr />
                            policy
                            <br />
                            (Privacy Policy)
                        </h2>
                    </div>
                    <div className="col-md-8">
                        <p>
                            Denna policy innehåller information om hur vi hanterar dina personuppgifter.
                            Vi uppmanar dig att läsa igenom detta dokument noggrant innan du samtycker
                            till att vi behandlar dina personuppgifter.
                        </p>
                        <h4 className="mt-5 mb-4">1. Centrala begrepp</h4>
                        <p>
                            Personuppgifter är information som enskilt eller tillsammans med andra uppgifter
                            kan användas för att identifiera, lokalisera eller kontakta en individ. Exempel
                            på personuppgifter är namn, telefonnummer och IP-adress.
                        </p>

                        <p>
                            Behandling av personuppgifter innebär all typ av hantering av personuppgifter, till
                            exempel: insamling, analysering, registrering och lagring.
                        </p>

                        <p>
                            Personuppgiftsansvarig är den som fastställer syftet med behandling av personuppgifter
                            och som bestämmer vilka personuppgifter som efterfrågas. Det är personuppgiftsansvarig
                            som är ytterst ansvarig för att behandlingen av dina personuppgifter sker enligt
                            gällande personuppgiftslagstiftning.
                        </p>

                        <h4 className="mt-5 mb-4">2. Svensk Portkod AB är personuppgiftsansvarig</h4>
                        <p>
                            Svensk Portkod AB är personuppgiftsansvarig för behandlingen av dina personuppgifter.

                        </p>
                        <h4 className="mt-5 mb-4">3. Vilka typer av information samlar vi in?</h4>
                        <p>
                            När du kontaktar oss, söker en tjänst, registrerar dig via ett formulär eller använder
                            vår chatt-funktion kan du bli ombedd att lämna ifrån dig information. Beroende på
                            situation kan vi komma att be om följande personuppgifter:
                        </p>
                        <ul>
                            <li>Namn</li>
                            <li>E-postadress</li>
                            <li>Telefonnummer</li>
                            <li>Andra uppgifter såsom fritextfrågor eller formulärsvar</li>
                            <li>
                                Teknisk data: den URL genom vilket du får åtkomst till våra webbsidor, din
                                IP-adress och användarbeteende, typ av webbläsare, språk och information om
                                identifiering och operativsystem.
                            </li>
                        </ul>
                        <h4 className="mt-5 mb-4">
                            4. Information från andra källor
                        </h4>
                        <p>
                            När du samtycker till att vi behandlar dina personuppgifter godkänner du även att
                            vi kan komma att registrera annan information om dig som du har lämnat till oss
                            tidigare i andra sammanhang. Baserat på offentligt tillgänglig information kan vi
                            även komma att komplettera dina registrerade uppgifter med bransch, ytterligare
                            kontaktinformation, information om tidigare arbetsplatser eller arbetstitel. Är du
                            kund till oss kan vi komma att komplettera dina uppgifter med ytterligare
                            kontaktinformation som du lämnat till oss via exempelvis tidigare jobbansökan
                            eller kunduppdrag.
                        </p>
                        <h4 className="mt-5 mb-4">
                            5. Hur använder vi informationen?
                        </h4>
                        <p>
                            Informationen som du ger ifrån dig används för följande ändamål:
                        </p>
                        <ul>
                            <li>Sälj- och marknadsföringsaktiviteter i form av direktutskick eller telefonkontakt</li>
                            <li>Kundvård och informationsinsatser kring våra erbjudanden och jobbmöjligheter</li>
                            <li>
                                För att få statistik och underlag kring användarbeteenden för att förbättra vår
                                hemsida och användarupplevelsen av den
                            </li>
                            <li>
                                Så att vi kan ge dig en mer personlig upplevelse och leverera produkterbjudanden
                                och annat innehåll som du kan vara intresserad av
                            </li>
                        </ul>
                        <p>Vi ber om dina kontaktuppgifter för att:</p>
                        <ul>
                            <li>Svara på förfrågningar och jobbansökningar</li>
                            <li>
                                Skicka efterfrågat material, eller på annat sätt uppfylla det vi åtagit oss i
                                utbyte mot att du skickar in dina uppgifter
                            </li>
                            <li>Upprätta och bibehålla en dialog</li>
                            <li>Skicka information som kan vara av intresse för dig</li>
                            <li>
                                Lägga till dig på utskickslistor för nyheter och annat innehåll du själv valt
                                att ta del av
                            </li>
                        </ul>

                        <h4 className="mt-5 mb-4">
                            6. Samtycke till epostutskick, direktmarknadsföring och fortsatt kontakt
                        </h4>
                        <p>
                            När du samtycker till att vi behandlar dina personuppgifter enligt de syften angiven
                            ovan samtycker du till att:
                        </p>
                        <ul>
                            <li>Vi behandlar dina personuppgifter enligt denna integritetspolicy</li>
                            <li>Vi kan skicka direktmarknadsföring till dig via e-post om våra tjänster</li>
                            <li>Vi får kontakta dig via e-post eller telefon</li>
                            <li>
                                Prenumerera på den typ av epostutskick du själv har valt att tacka ja till.
                                Du kan uppdatera dina prenumerationsinställningar och tacka nej till vidare
                                utskick genom att följa den länk som du hittar längst ner i alla våra utskick,
                                eller genom att kontakta oss direkt.
                            </li>
                        </ul>
                        <h4 className="mt-5 mb-4">7. Hur länge sparas uppgifterna?</h4>
                        <p>
                            Vi behandlar bara personuppgifter så pass länge att vi kan uppfylla syftet för deras
                            insamling, därefter raderar vi datan.
                        </p>
                        <h4 className="mt-5 mb-4">8. Vem kan ta del av uppgifterna?</h4>
                        <p>
                            Lämnade uppgifter kommer att vara tillgängliga för ett begränsat antal personer inom
                            företaget som arbetar på avdelningarna för marknad, försäljning, leverans och
                            kandidathantering.
                        </p>
                        <h4 className="mt-5 mb-4">
                            9. Vi delar viss information inom ramen för samarbete med tredje part
                        </h4>
                        <p>
                            Vi säljer inte dina personuppgifter till tredje part. Finns det en pågående sälj-,
                            kund-, eller rekryteringsdialog mellan dig, oss och någon av våra samarbetspartners
                            kan vi komma att dela information om dig i form av:
                        </p>
                        <ul>
                            <li>Namn</li>
                            <li>E-postadress</li>
                            <li>Telefonnummer</li>
                            <li>Vilket företag du arbetar för</li>
                        </ul>
                        <p>
                            Har vi registrerat dina uppgifter i samband med ett event som vi genomför tillsammans
                            med en extern part kan vi komma att överföra samma kategorier av personuppgifter som
                            angivet ovan samt eventuellt svar på ytterligare formulärfrågor eller fritextsvar.
                        </p>
                        <h4 className="mt-5 mb-4">
                            10. Hur skyddar vi dina uppgifter?
                        </h4>
                        <p>
                            Din personliga information lagras bakom säkrade nätverk och är tillgänglig för enbart
                            ett begränsat antal personer som har speciella rättigheter till dessa system. Dessa
                            personer är skyldiga att hålla informationen konfidentiell. Dessutom, all känslig
                            information som du anger är krypterad via SSL (Secure Socket Layer technology).
                        </p>
                        <p>
                            Uppgifterna hanteras inom ramen för dataskyddsförordningen, GDPR. All information
                            omfattas av sekretssavtal och vår personal har tillgång till endast de system och
                            funktioner som deras arbetsuppgifter kräver.
                        </p>
                        <h4 className="mt-5 mb-4">
                            11. Du har rätt att få veta vilka uppgifter vi har om dig
                        </h4>
                        <p>
                            Från och med den 25:e maj 2018 börjar den nya dataskyddslagen att gälla. Du har då rätt
                            att få information om vilka uppgifter vi har om dig i form av ett registerutdrag. Du kan
                            även begära att vi rättar felaktiga uppgifter eller raderar information om dig. Du har
                            också rätt att dra tillbaka ditt samtycke för att vi behandlar dina personuppgifter. Drar
                            du tillbaka ditt samtycke kommer vi att ta bort de uppgifter vi har om dig. Observera att
                            radering av dina personuppgifter hos oss kan påverka leveransen av information inom ramen
                            för en kundrelation, samt eventuellt påverka pågående dialoger.
                        </p>
                        <p>
                            Vill du dra tillbaka ditt samtycke, eller begära registerutdrag, rättning eller radering
                            tar du kontakt med oss på den e-postadress vi har angett under avsnittet för
                            kontaktuppgifter. För att ta del av ditt registerutdrag behöver du skicka en elektronisk
                            kopia på ett signerat dokument med din förfrågan.
                        </p>
                        <h4 className="mt-5 mb-4">
                            12. Kontaktuppgifter till Svensk Portkod AB
                        </h4>
                        <p>
                            Svensk Portkod AB är personuppgiftsansvarig för behandlingen av dina personuppgifter.
                            Har du frågor om vår behandling eller önskar komma i kontakt med oss för att utöva dina
                            rättigheter hittar du våra kontaktuppgifter nedan:
                        </p>
                        <p>
                            Svensk&nbsp;Portkod&nbsp;AB
                            <br />
                            Fatburs&nbsp;Kvarngata&nbsp;44
                            <br />
                            118&nbsp;64&nbsp;Stockholm
                        </p>
                        <p><a href="mailto:info@svenskportkod.se">info@svenskportkod.se</a></p>
                        <p>Org. nr. 559047-8367</p>
                    </div>
                </div>
            </section>
        </main>
    </>
);
