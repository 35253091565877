import React from 'react';
import Nav from './nav';

export default () => (
    <>
        <header className="sp-white pb-5">
            <Nav color={true} />
        </header>
        <main className="sp-white">
            <section className="container">
                <div className="row p-5">
                    <div className="col-md-4 mb-5">
                        <h1>
                            Frågor och svar
                        </h1>
                    </div>
                    <div className="col-md-8">
                        <h4 className="mb-4">Vad gör Svensk Portkod?</h4>
                        <p>
                            Alla fastighetsägare behöver förse behöriga besökare med portkoder för att serva boende med
                            exempelvis post, tidningar, skydd och akutsjukvård.
                        </p>
                        <p>
                            För säkerhets skull bör du byta den speciella ”postkoden” med jämna mellanrum. Koden ändrar
                            vi inte åt dig, men vi distribuerar den nya koden till av fastighetsbranschen legitimerade
                            operatörer på ett enkelt och säkert sätt.
                        </p>
                        <p>
                            Som fastighetsägare slipper du skicka e- post, ringa till kundtjänster och hålla reda på
                            vilka som ska informeras. Det sköter Svensk Portkod AB åt dig.
                        </p>
                        <p>
                            Svensk Portkod bildades 2016 för att lösa ett långvarigt problem för fastighetsägare som
                            ofta behöver byta portkod men inte vet var man ska vända sig. Bakom företaget finns lång
                            erfarenhet inom postbranschen där inpasseringsproblem hos fastighetsägare har varit en
                            vardag. VD och grundare är Lasse Wennberg som även driver
                            {' '}
                            <a href="http://svenskinpassage.se/">Svensk Inpassage AB</a>
                            .
                        </p>
                        <h4 className="mt-5 mb-4">Vad skiljer portkod, boendekod och postkod?</h4>
                        <p>
                            <em>Portkod</em>
                            &nbsp; är samlingsnamnet för alla koder som används för att låsa en fastighet.
                        </p>
                        <p>
                            <em>Boendekod</em>
                            &nbsp; är en kod som tilldelas de boende i fastigheten.
                        </p>
                        <p>
                            <em>Postkod</em>
                            &nbsp; är en kod som tilldelas post- och tidningsutdelare. Det är postkoder Svensk Portkod
                            hjälper fastighetsägare att förmedla till behöriga besökare som postoperatörer och
                            tidningsdistributörer.
                        </p>
                        <p>Du kan ange olika koder för postutdelning och tidningsutdelning. Tänk på att postutdelning sker
                            dagtid medan tidningsutdelning vanligen sker nattetid, så kodernas giltighet måste anpassas efter
                            det.
                        </p>
                        <h4 className="mt-5 mb-4">Hur ofta bör man byta portkod?</h4>
                        <p>
                            SSF Stöldskyddsföreningen rekommenderar att man ändrar sin postportkod minst en gång per år,
                            och i särskilt utsatta områden oftare än så för att inte obehöriga ska komma in i
                            fastigheten.
                        </p>
                        <h4 className="mt-5 mb-4">Vad kostar tjänsten?</h4>
                        <p>
                            Kostnaden för tjänsten är 399 kr per beställning, plus moms. En beställning är en
                            rapportering av
                            ändring till en ny kod för ett antal portar på ett specifikt datum. Du kan ha många
                            portar i en beställning, men de måste alla få samma nya kod på samma datum.
                        </p>
                        <p>
                            Behöver du ändra till olika koder för olika portar, eller vid olika tillfällen måste
                            du göra flera beställningar.
                        </p>
                        <h4 className="mt-5 mb-4">
                            Hur mycket framförhållning bör man ha?
                        </h4>
                        <p>
                            Vi förmedlar ändringen samma dag som en beställning kommer in. Sedan beror det på
                            besökarens rutiner hur snabbt de uppdaterar sina system. De flesta besökarens gör det inom
                            ett par dagar men vi rekommenderar en veckas framförhållning för att vara säker.
                        </p>
                        <h4 className="mt-5 mb-4">
                            Hur sker betalningen?
                        </h4>
                        <p>
                            Betalning sker via faktura inom 30 dagar från beställningsdatum. Faktura via brev kostar
                            40 kr extra för kuvertering och porto.
                        </p>
                        <h4 className="mt-5 mb-4">Får vi själv välja kod?</h4>
                        <p>Ja, ni kan välja såväl kod som första dag koden gäller.</p>

                        <h4 className="mt-5 mb-4" id="behoriga-besokare">Till vilka behöriga besökare distribuerar Svensk Portkod?</h4>
                        <p>
                            Vi distribuerar för tillfället till
                        </p>
                        <ul>
                            <li>Bonway</li>
                            <li>Citymail</li>
                            <li>Early Bird</li>
                            <li>NWT Direkt</li>
                            <li>Point</li>
                            <li>Postnord</li>
                            <li>Premo</li>
                            <li>Svensk Hemleverans</li>
                            <li>Tidningstjänst</li>
                            <li>VTD</li>
                        </ul>
                        <h4 className="mt-5 mb-4" id="hjalp">Vem kan hjälpa mig byta koden i min port?</h4>
                        <p>
                            Behöver ni hjälp att faktiskt ändra koden i er port? Svensk Portkod samarbetar
                            med följande erfarna låssmeder som gärna hjälper er
                        </p>
                        <p>
                            BORÅS
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.lasteam.se/kontor/boras/"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Låsteam
                                </a>
                            </li>
                        </ul>
                        <p>
                            GÖTEBORG
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.lassmeden-sa.se/"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Låssmeden Sven Alexandersson
                                </a>
                            </li>
                        </ul>
                        <p>
                            LINKÖPING
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.ostgotalas.se"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Östgötalås
                                </a>
                            </li>
                        </ul>
                        <p>
                            SKÖVDE
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.lasteam.se/kontor/skovde/"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Låsteam
                                </a>
                            </li>
                        </ul>
                        <p>
                            STOCKHOLM
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.baxec.se"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Baxec
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.bergslas.se"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Bergs Lås
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.lasteam.se/kontor/stockholm/"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Låsteam
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.pmlarm.se"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    PM Larm
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://sakerhetsintegrering.se/"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Säkerhetsintegrering
                                </a>
                            </li>
                        </ul>
                        <p>
                            VÄSTERÅS
                        </p>
                        <ul>
                            <li>
                                <a
                                    href="https://www.jourlas.se"
                                    target="_new"
                                    className="underlined-link"
                                >
                                    Jourlås Sverige
                                </a>
                            </li>
                        </ul>
                        <h4 className="mt-5 mb-4">Kontaktuppgifter</h4>
                        <address>
                            Svensk Portkod AB
                            <br />
                            Fatburs Kvarngata 44
                            <br />
                            118 64 Stockholm
                        </address>
                        <p>
                            Org.nr. 559047-8367
                        </p>
                        <h4 className="mt-5 mb-4">Är din fråga inte besvarad?</h4>
                        <p>
                            Använd knappen nere till höger eller kontakta oss på
                            {' '}
                            <a href="mailto:info@svenskportkod.se">info@svenskportkod.se</a>
                        </p>
                    </div>
                </div>
            </section>
        </main>
    </>
);
