/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import LinkedInTag from 'react-linkedin-insight';
import envelope from './envelope.svg';
import remove from './close.svg';

async function submit(formData) {
    ReactGA.event('feedback_send');
    ReactGA.event('generate_lead');
    LinkedInTag.track('12587604');

    const response = await fetch('/api/feedback', {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(formData),
    });

    if (!response.ok) {
        console.error('sending feedback failed:', response.statusText);
    }
}

function HomescreenForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    async function submitForm(e) {
        e.preventDefault();

        const formData = {
            name,
            email,
            message
        };
        setSending(true);
        await submit(formData);
        setSending(false);
        setSent(true);
    }

    function sendEnabled() {
        const emailRegex = /\S+@\S+\.\S+/;
        return (!sending && name.length >= 2 && emailRegex.test(email));
    }

    return <div className="mt-2 ">
        {sent ? <i>Tack för visat intresse.Vi hör av oss omgående!</i>
            : <form className="mt-2">
                <input
                    name="name"
                    placeholder="Namn"
                    className="w-100 p-2"
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    name="email"
                    placeholder="Epost"
                    className="w-100 my-3 p-2"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    id="message"
                    name="message"
                    placeholder="Fråga eller meddelande (valfritt)"
                    className="w-100 p-2"
                    rows="3"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <div className="mx-auto">
                    <button
                        type="submit"
                        className="btn btn-secondary mt-3 mx-auto"
                        alt="Skicka"
                        disabled={!sendEnabled()}
                        onClick={submitForm}
                    >
                        Skicka
                    </button>
                </div>
            </form>
        }
    </div>;
}

function FeedbackForm(props) {
    const { closeAction } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    async function submitForm(e) {
        e.preventDefault();

        const formData = {
            name,
            email,
            message,
        };

        setSending(true);
        await submit(formData);
        setSending(false);
        setSent(true);
    }

    function sendEnabled() {
        const emailRegex = /\S+@\S+\.\S+/;
        return (!sending && name.length >= 2 && emailRegex.test(email) && message.length >= 5);
    }

    return (
        <div className="d-flex flex-column sp-white shadow-lg">
            <div className="sp-blue d-flex p-2">
                <b className="flex-grow-1">
                    Fråga oss
                </b>
                <button type="button" className="btn-no-frame" onClick={closeAction}>
                    <img src={remove} width="16" alt="Stäng" />
                </button>
            </div>
            {sent
                ? (
                    <div>
                        <div className="p-3">
                            <div className="">
                                Tack för ditt meddelande. Vi hör av oss omgående!
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="p-3">
                        <div className="">
                            Har du frågor om portkodsbyte eller Svensk Portkod? Vi har svar!
                        </div>
                        <form className="mt-2">
                            <label htmlFor="name"><small>Ditt namn</small></label>
                            <input
                                name="name"
                                className="w-100"
                                id="name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <label htmlFor="email" className="mt-2"><small>Din epost</small></label>
                            <input
                                name="email"
                                className="w-100"
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="message" className="mt-2"><small>Fråga eller meddelande</small></label>
                            <textarea
                                id="message"
                                name="message"
                                className="w-100"
                                rows="5"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <button
                                type="submit"
                                className="btn btn-primary"
                                alt="Skicka"
                                disabled={!sendEnabled()}
                                onClick={submitForm}
                            >
                                Skicka
                            </button>
                        </form>
                    </div>
                )}
        </div>
    );
}

function FeedbackPopup() {
    const [open, setOpen] = useState(false);

    function openFeedback() {
        ReactGA.event('feedback_open');
        setOpen(true);
    }

    return (
        <div
            className="fixed-bottom d-flex justify-content-end"
            style={{ left: 'unset' }}
        >
            {open ? <FeedbackForm closeAction={() => setOpen(false)} />
                : (
                    <button
                        type="button"
                        className="m-3 mr-sm-5 btn btn-light-blue shadow-lg"
                        onClick={openFeedback}
                    >
                        <img src={envelope} alt="Envelope" width="20" />
                        <span className="d-none d-sm-inline-block ml-2">Frågor?</span>
                    </button>
                )}
        </div>
    );
};

export { FeedbackPopup, HomescreenForm };
