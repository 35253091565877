import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';

import BrochureComponent from './components/brochure/brochure';
import FaqComponent from './components/faq';
import FooterComponent from './components/footer';
import PrivacyComponent from './components/privacy';
import CookieComponent from './components/cookie';
import { FeedbackPopup } from './components/feedback';
import BlogComponent from './components/blog';
import NoMatch from './components/nomatch';

const OrderComponent = React.lazy(() => import('./components/order/order'));

import ReactGA from 'react-ga4';
import LinkedInTag from 'react-linkedin-insight';

LinkedInTag.init('1784228');
ReactGA.initialize([{ trackingId: 'G-G6LXRF4171' }, { trackingId: 'AW-11218358744' }]);

function Analytics() {
    // const location = useLocation();
    // const orderIdRegex = /[0-9a-f]{24}/;
    // const formattedLocation = location.pathname.replace(orderIdRegex, 'id');
    // ReactGA.send({ hitType: 'pageview', page: formattedLocation });

    window.scrollTo(0, 0);
    return null;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const query = useQuery();
    const queryCoupon = query.get('rabatt');

    if (queryCoupon) {
        console.log('setting coupon code to', queryCoupon);
        sessionStorage.setItem('couponCode', queryCoupon);
    }

    const DEFAULT_TITLE = 'Svensk Portkod - där du säkert ändrar din portkod';

    const TITLES = {
        'faq': 'Svensk Portkod - Frågor och svar',
        'blogg': 'Svensk Portkod - Blogg',
        'personuppgifter': 'Svensk Portkod - Personuppgiftspolicy',
        'order': 'Svensk Portkod - Meddela ändring',
    };

    const location = useLocation()
    useEffect(() => {
        const first = location.pathname.split('/')[1];
        document.title = TITLES[first] ?? DEFAULT_TITLE
    }, [location]);

    return (
        <div>
            {/* <CookieComponent /> */}
            <Analytics />
            <Switch>
                <Route exact path="/">
                    <BrochureComponent />
                </Route>
                <Route path="/faq">
                    <FaqComponent />
                </Route>
                <Route path="/personuppgifter">
                    <PrivacyComponent />
                </Route>
                <Route path="/blogg">
                    <BlogComponent />
                </Route>
                <Route path="/blog">
                    <Redirect to="/blogg" />
                </Route>
                <Route path="/order">
                    <Suspense fallback={<div>Laddar...</div>}>
                        <OrderComponent />
                    </Suspense>
                </Route>
                <Route path="*">
                    <NoMatch />
                </Route>
            </Switch>
            <FooterComponent />
            <FeedbackPopup />
        </div>
    );
}


const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(<Router><App /></Router>);
