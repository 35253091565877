import React, { useEffect, useState } from 'react';
import {
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    Switch,
    Route,
} from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import yaml from 'js-yaml';
import Nav from './nav';
import './blog.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function BlogPost(params) {
    const { post } = params;

    return <div className="blog-post mb-5">
        <a href={`/blogg/${post.slug}`}><h2>{post.title}</h2></a >
        <div className="blog-body"><ReactMarkdown>{post.body_markdown}</ReactMarkdown></div>
    </div>;
}

function AllBlogs(props) {
    const { posts } = props;
    const query = useQuery();
    const after = query.get('after');

    const [postsToShow, setPostsToShow] = useState([]);
    const [nextAfter, setNextAfter] = useState('');

    useEffect(() => {
        async function loadPosts() {
            if (posts.length == 0) {
                return;
            }
            const MAX_POSTS = 5;
            let start = 0;
            if (after) {
                for (let i = 0; i < posts.length; i++) {
                    if (posts[i].slug == after && posts.length > i + 1) {
                        start = i + 1;
                    }
                }
            }

            const postsToShow = posts.slice(start, start + MAX_POSTS);
            setNextAfter(postsToShow[postsToShow.length - 1].slug);
            setPostsToShow(postsToShow);
        }
        loadPosts();
    }, [posts]);

    return <>
        {postsToShow.map((post, index) => <BlogPost key={"blogg-" + index} post={post} />)}
        {nextAfter ? <a href={`/blogg?after=${nextAfter}`} style={{ textDecoration: 'underline' }}>Nästa</a> : null}
    </>;
}

function Blog(props) {
    const { posts } = props;
    const { blogId } = useParams();
    const [post, setPost] = useState([]);

    useEffect(() => {
        async function fetchPosts() {
            for (const post of posts) {
                if (post.slug == blogId) {
                    setPost(post);
                    document.title = `Svensk Portkod - ${post.title}`;
                    break;
                }
            }
        }
        fetchPosts();
    }, [posts]);

    return <>
        {post ? <BlogPost post={post} /> : <div className="pb-5">Hittade inte blogginlägget</div>}
        <p>
            <Link to="/blogg" style={{ textDecoration: 'underline' }}>Fler blogginlägg</Link>
        </p >
    </>;
}

export default () => {
    const { path } = useRouteMatch();

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchPosts() {
            const response = await fetch('/static/blog.yml');

            if (!response.ok) {
                console.error('failed to read blogs:', response.statusText);
                return;
            }

            const text = await response.text();
            const posts = yaml.loadAll(text);
            const publishedPosts = posts.filter(post => post.published);

            setPosts(publishedPosts);
        }
        fetchPosts();
    }, []);

    return (<>
        <header className="sp-white pb-5">
            <Nav color={true} />
        </header>
        <main className="sp-white">
            <section className="container">
                <div className="row p-2">
                    <div className="col-md-4 mb-5">
                        <h1>
                            <Link to="/blogg">Blogg</Link>
                        </h1>
                        <ul className="blog-list pt-4 d-none d-md-block">
                            {posts.map(p => <li className="pb-2" key={p.slug}><a href={`/blogg/${p.slug}`}>{p.title}</a></li>)}
                        </ul>
                    </div>
                    <div className="col-md-8 pb-5">
                        <Switch>
                            <Route exact path={path}>
                                <AllBlogs posts={posts} />
                            </Route>
                            <Route path={`${path}/:blogId`}>
                                <Blog posts={posts} />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </section>
        </main >
    </>
    );
};
